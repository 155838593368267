import React from 'react';
import './Referrals.css';
import LinkShort from '../../Components/LinkShort/LinkShort';
import PannelNavbar from '../../Components/PannelNavbar/PannelNavbar';
import { UilExchangeAlt } from '@iconscout/react-unicons';
import { PannelFooter } from '../../Components/PannelFooter/PannelFooter';

const Referrals = () => {
    return (
        <>
            <div className="referral-wrapper">
                <PannelNavbar />
                <div className="referral-r">
                    <LinkShort />

                    <div className="referral">

                        <div className="referral-link">
                            <h6>The Link Shortener referral program is
                                a great way to spread the word of this great
                                service and to earn even more money with your
                                short links! Refer friends and receive 20% of
                                their earnings for life!
                            </h6>
                            <input type="text" value='https://hblinks.in/ref/108421187033044144383' disabled />
                        </div>

                        <div className="referral-all">
                            <div className='referral-blue-box'>
                                <h2><UilExchangeAlt />My Referrals</h2>
                            </div>

                            <div className='table-ref'>
                                <table className='referral-table'>
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>


                    <PannelFooter/>
                </div>
            </div>
        </>
    )
}

export default Referrals