import React from 'react';
import LinkShort from '../../Components/LinkShort/LinkShort';
import { PannelFooter } from '../../Components/PannelFooter/PannelFooter';
import PannelNavbar from '../../Components/PannelNavbar/PannelNavbar';
import './Support.css'

const Support = () => {
    return (
        <>
            <div className="support-wrapper">
                <PannelNavbar />
                <div className="support-r">
                    <LinkShort />
                    <div className="support">
                        <div className="support-box">
                            <div>
                                <h1>Name</h1>
                                <input type="text"/>
                            </div>

                            <div>
                                <h1>Subject</h1>
                                <input type="text"/>
                            </div>

                            <div>
                                <h1>Email</h1>
                                <input type="Email"/>
                            </div>

                            <div>
                                <h1>Message</h1>
                                <textarea cols="30" rows="10" placeholder='Type Your Message Here ...'></textarea>
                            </div>

                            <div>
                                <input type="checkbox" />
                                <h2>I consent to collect my name and email. For more details check our <span>Privacy Policy</span>.</h2>
                            </div>

                            <button>Submit</button>
                        </div>
                    </div>
                    <PannelFooter />
                </div>
            </div>
        </>
    )
}

export default Support