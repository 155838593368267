import './App.css';
import {BrowserRouter , Routes , Route} from 'react-router-dom';
import Main from './Pages/Main/Main';
import Pannel from './Pages/Pannel/Pannel';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import Referrals from './Pages/Referrals/Referrals';
import Withdraws from './Pages/Withdraws/Withdraws';
import Support from './Pages/Support/Support';
function App() {
  return (
    <>

    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Main/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/register' element={<Register/>}/>
      <Route path='/Pannel' element={<Pannel/>}/>
      <Route path='/Pannel/referrals' element={<Referrals/>}/>
      <Route path='/Pannel/withdraws' element={<Withdraws/>}/>
      <Route path='/Pannel/support' element={<Support/>}/>
    </Routes>
    </BrowserRouter>

    </>
  );
}

export default App;
