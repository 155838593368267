import React from 'react';
import './LinkShort.css';
import { UilBars } from '@iconscout/react-unicons'
import { UilUser } from '@iconscout/react-unicons'

const LinkShort = () => {
    return (

        <div className="newLink">
            <div>
                <UilBars />
                <h1>+ New Shorten Link</h1>
            </div>
            <div>
                <UilUser />
            </div>
        </div>
    )
}

export default LinkShort