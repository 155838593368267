import React from 'react';
import './Navbar.css';
import LOGO from '../../img/Navbar/logo.png';
import { UilSearchAlt } from '@iconscout/react-unicons';
import { UilBars } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <>
      <div className="navbar-wrapper">
        <div className="navbar-l">
          <div className="navbar-logo">
            <Link to='/'><img src={LOGO} alt="" /></Link>
          </div>
        </div>
        <div className="navbar-b">
          <ul>
            <Link to='/'><li>Company</li></Link>
            <Link to='/pannel'><li>Plane</li></Link>
            <li>Services</li>
            <Link to='/login'><li>Login</li></Link>
            <Link to='/register'><li>Register</li></Link>
          </ul>
        </div>
        <div className="navbar-r">
          <div className="navbar-Country">
          </div>
          <h3>EN</h3>
          <span className='searchuil' id='searchuil'><UilSearchAlt/></span>
          <div className="navbar-toggle">
            <span><UilBars /></span>
          </div>
        </div>

      </div>
    </>
  )
}

export default Navbar