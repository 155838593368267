import React from 'react';
import './Register.css';
import Navbar from '../../Components/Navbar/Navbar';
import { UilArrowRight } from '@iconscout/react-unicons';
import Footer from '../../Components/Footer/Footer';

const Register = () => {
  return (
    <>
      <div className="login-wrapper">
        <Navbar />
        <div className="login">
          <h1>Publisher Sign Up</h1>
        </div>
      </div>

      <div className="Register-main">
        <div>
          <h1>Register Here</h1>
          <div className='register'>

            <h4>Name <span>*</span></h4>
            <input type="text" placeholder='Name' />

            <h4>Email <span>*</span></h4>
            <input type="email" placeholder='Email' />

            <h4>Country <span>*</span></h4>
            <select>
              <option>Country</option>
              <option>Pakistan</option>
              <option>India</option>
              <option>China</option>
              <option>Iraq</option>
              <option>Dubai</option>
            </select>

            <h4>Main Trafic Source <span>*</span></h4>
            <div className='checkbox'>
              <div>
                <input type="checkbox" /><h5>Site</h5>
              </div>
              <div>
                <input type="checkbox" /><h5>Youtube</h5>
              </div>
              <div>
                <input type="checkbox" /><h5>Social</h5>
              </div>
            </div>

            <h4>Site Url | Youtube Channel Url | Social Pages Url <span>*</span></h4>
            <input type="text" placeholder='url' />

            <h4>Monthly Impressions <span>*</span></h4>
            <select>
              <option>0 - 50,000</option>
              <option>50,000 - 500,000</option>
              <option>500,000 - 5.000,000</option>
              <option>5.000,000 +</option>
            </select>

            <h4>Do you have +18 content? <span>*</span></h4>
            <div className='checkbox'>
              <div>
                <input type="checkbox" /><h5>Yes</h5>
              </div>
              <div>
                <input type="checkbox" /><h5>No</h5>
              </div>
            </div>

            <h4>Personal Direct Contact <span>*</span></h4>
            <input type="text" placeholder='Skype ID , WhatsApp or Discord' />

            <h4>Provide a brief description of your traffic source and where you will place your links <span>*</span></h4>
            <textarea cols="30" rows="10"></textarea>

            <div className='checkbox'>
              <div>
                <input type="checkbox" /><h6>By continuing you agree to our <span>Terms of Service</span> & <span>Privacy Policy</span></h6>
              </div>
            </div>

            <button>Sign Up </button>
          </div>
        </div>
      </div>


      <div className="Already">
        <div>

          <h1>Do you already have an Account?</h1>
          <button>Sign In<UilArrowRight /></button>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Register