import React from 'react';
import './Pannel.css';
import { UilUsersAlt } from '@iconscout/react-unicons';
import { UilDashboard } from '@iconscout/react-unicons'
import { UilAngleDoubleDown } from '@iconscout/react-unicons';
import { UilArrowUp } from '@iconscout/react-unicons'
import { UilArrowGrowth } from '@iconscout/react-unicons'
import { UilUniversity } from '@iconscout/react-unicons';
import { UilChannel } from '@iconscout/react-unicons';
import { UilPercentage } from '@iconscout/react-unicons';
import { UilEye } from '@iconscout/react-unicons';
import { UilDollarSign } from '@iconscout/react-unicons';
import LinkShort from '../../Components/LinkShort/LinkShort';
import PannelNavbar from '../../Components/PannelNavbar/PannelNavbar';
import { PannelFooter } from '../../Components/PannelFooter/PannelFooter';

const Pannel = () => {
  return (

    <>
      <div className="pannel-wrapper">
        <PannelNavbar />
        <div className="pannel-r">
          <LinkShort />
          <div className="report">

            <div className="TodayReport">
              <h1>Today's report <span>/</span> 21 August 12:48 AM</h1>
            </div>

            <div className="report-boxs">

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>Views</h3>
                      <h4>0</h4>
                    </div>
                    <div className="report-img" >
                      <UilArrowGrowth />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> 21-August</h5>
                </div>
              </div>

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>EARNINGS</h3>
                      <h4>$0.00000</h4>
                    </div>
                    <div className="report-img" style={{ background: 'linear-gradient(87deg,#11cdef 0,#1171ef 100%)' }}>
                      <UilUniversity />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> 21-August</h5>
                </div>
              </div>

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>REF EARN</h3>
                      <h4>$0.00000</h4>
                    </div>
                    <div className="report-img" style={{ background: 'linear-gradient(87deg,#fb6340 0,#fbb140 100%)' }}>
                      <UilChannel />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> 21-August</h5>
                </div>
              </div>

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>AVG CPM</h3>
                      <h4>0</h4>
                    </div>
                    <div className="report-img" style={{ background: 'linear-gradient(87deg,#8965e0 0,#bc65e0 100%)' }}>
                      <UilPercentage />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> 21-August</h5>
                </div>
              </div>

            </div>

            <div className="TodayReport">
              <h1>This month's report <span>/</span> August-2022</h1>
            </div>

            <div className="report-boxs">

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>Views</h3>
                      <h4>0</h4>
                    </div>
                    <div className="report-img" >
                      <UilEye />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> August-2022</h5>
                </div>
              </div>

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>EARNINGS</h3>
                      <h4>$999990</h4>
                    </div>
                    <div className="report-img" style={{ background: 'linear-gradient(87deg,#11cdef 0,#1171ef 100%)' }}>
                      <UilDollarSign />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> August-2022</h5>
                </div>
              </div>

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>REF EARN</h3>
                      <h4>$0.00000</h4>
                    </div>
                    <div className="report-img" style={{ background: 'linear-gradient(87deg,#fb6340 0,#fbb140 100%)' }}>
                      <UilUsersAlt />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> August-2022</h5>
                </div>
              </div>

              <div className="report-box">
                <div className="report-box-l">
                  <div>
                    <div>
                      <h3>AVG CPM</h3>
                      <h4>0</h4>
                    </div>
                    <div className="report-img" style={{ background: 'linear-gradient(87deg,#8965e0 0,#bc65e0 100%)' }}>
                      <UilPercentage />
                    </div>
                  </div>
                  <h5><span><UilArrowUp />100%</span> August-2022</h5>
                </div>
              </div>

            </div>

          </div>


          <div className="announcement">
            <div className="anno-wrapper">

              <div className='blue-box'>
                <h2>Announcements</h2>
              </div>

              <div className="anno-main">


                <div>
                  <h1><UilAngleDoubleDown />Earn 50$ Cash Instantly $$$</h1>
                  <h3><UilDashboard />7/5/22, 2:08 AM</h3>
                </div>

                <h4>How to earn ???</h4>
                <p>Create a YouTube video about HBLinks Shortner then when
                  your video reach average views on YouTube you'll get your
                  cash rewards.
                </p>

                <ul>
                  <li><span>*</span> 1k  Average Views = 5$</li>
                  <li><span>*</span> 3k Average Views = 15$</li>
                  <li><span>*</span> 5k Average Views = 30$</li>
                </ul>

                <p>
                  <span>Note:</span> When your video reach Average views then inform us
                  (contacthblinks@gmail.com) with your YouTube Channel
                  contact email for taking Payment
                </p>

              </div>
            </div>
          </div>

          <div className="graph">
            <div className="graph-wrapper">

              <div className='graph-blue-box'>
                <h2>Statistics</h2>

                <select>
                  <option>August 2020</option>
                  <option>August 2021</option>
                </select>
              </div>

            </div>
          </div>

          <div className="earnhistory">
            <div className="earnhistory-wrapper">

              <div className='blue-box'>
                <h2>Reports</h2>
              </div>

              <div className="table">
                <table>

                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Views</th>
                      <th>Link Earnings</th>
                      <th>Daily CPM</th>
                      <th>Referral Earnings</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$9999.000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>


                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$0.000000</td>
                    </tr>

                    <tr>
                      <td>2022-08-01</td>
                      <td>0</td>
                      <td>$0.000000</td>
                      <td>0</td>
                      <td>$999999</td>
                    </tr>

                  </tbody>

                </table>
              </div>


            </div>
          </div>

          <div className="topLink">
            <div className="topLink-wrapper">
              <div className='blue-box'>
                <h2>Top 10 Links</h2>
              </div>

              <table className="toplink-table">
                <thead>
                  <tr>
                    <th>Alias</th>
                    <th>Views</th>
                    <th>Link Earnings</th>
                  </tr>
                </thead>
              </table>

            </div>
          </div>
          <PannelFooter />
        </div>
      </div>

    </>
  )
}

export default Pannel