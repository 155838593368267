import React from 'react';
import './PannelFooter.css';

export const PannelFooter = () => {
    return (
        <>
            <div className="pannel-copy">
                <h1>Copyright © <span style={{color:'#2985fd'}}>Hasnain</span> Shortener 2022</h1>
            </div>
        </>
    )
}
