import React from 'react';
import './PannelNavbar.css';
import { UilCapsule } from '@iconscout/react-unicons';
import { UilWallet } from '@iconscout/react-unicons';
import { UilNotebooks } from '@iconscout/react-unicons';
import { UilSetting } from '@iconscout/react-unicons';
import { UilLifeRing } from '@iconscout/react-unicons';
import Avatar from '../../img/Pannel/avatar1.png';
import { UilDashboard } from '@iconscout/react-unicons'
import { UilAngleDoubleDown } from '@iconscout/react-unicons';
import { UilUsersAlt } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const PannelNavbar = () => {
    return (
        <>

            <div className="pannel-l">
                <h1>Link Shorter</h1>
                <div className="profile">
                    <div>
                        <img src={Avatar} alt="" />
                    </div>
                    <h3>128313139124149131</h3>
                </div>

                <div className="pannel-navbar">
                    <ul>
                        <Link to='/pannel'> <li><UilDashboard style={{ color: 'blue' }} />Dashboard</li></Link>
                        <li><UilCapsule style={{ color: '#2985fd' }} />Manage Links <UilAngleDoubleDown /> </li>
                        <Link to='/Pannel/withdraws'><li><UilWallet style={{ color: 'purple' }} />Payments</li></Link>
                        <li><UilNotebooks style={{ color: 'lightcoral ' }} />Tools <UilAngleDoubleDown /></li>
                        <Link to='/pannel/Referrals'><li><UilUsersAlt style={{ color: 'orange ' }} />Referrals</li></Link>
                        <li><UilSetting style={{ color: 'green ' }} />Setting <UilAngleDoubleDown /></li>
                        <Link to='/pannel/support'><li><UilLifeRing style={{ color: 'red ' }} />Support</li></Link>
                    </ul>
                </div>
            </div>

        </>
    )
}

export default PannelNavbar