import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import './Login.css';
import { UilArrowRight } from '@iconscout/react-unicons'
import Footer from '../../Components/Footer/Footer';




const Login = () => {

    //     
    return (
        <>
            <div className="login-wrapper">
                <Navbar />
                <div className="login">
                    <h1>Publisher Sign In</h1>
                </div>
            </div>


            <div className="login-main">
                <div>
                    <h1>Sign In Here</h1>
                    <div className='register'>
                        <h4>Username or e-mail <span>*</span></h4>
                        <input type="text" placeholder='Username or e-mail' />
                        <h4>Password <span>*</span></h4>
                        <input type="text" placeholder='Password' />
                        <button>Login </button>
                    </div>
                </div>
            </div>

            <div className="Already">
                <div>

                    <h1>You Dont have an Account?</h1>
                    <button>Register<UilArrowRight /></button>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Login