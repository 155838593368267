import React from 'react';
import LinkShort from '../../Components/LinkShort/LinkShort';
import { PannelFooter } from '../../Components/PannelFooter/PannelFooter';
import PannelNavbar from '../../Components/PannelNavbar/PannelNavbar';
import './Withdraws.css';
import { UilExchangeAlt } from '@iconscout/react-unicons';


const Withdraws = () => {
  return (
    <div className="withdraw-wrapper">
      <PannelNavbar />
      <div className="withdraw-r">
        <LinkShort />

        <div className="withdraw">

          <div className="payment">
            <div className="payment-box">
              <div className="p-boxL">
                <UilExchangeAlt />
                <div>
                  <h3>Available Balance</h3>
                  <h5>$0.100000</h5>
                </div>
              </div>
            </div>

            <div className="payment-box">
              <div className="p-boxL">
                <UilExchangeAlt />
                <div>
                  <h3>Pending Withdrawn</h3>
                  <h5>$0.000000</h5>
                </div>
              </div>
            </div>


            <div className="payment-box">
              <div className="p-boxL">
                <UilExchangeAlt />
                <div>
                  <h3>Total Withdraw</h3>
                  <h5>$0.000000</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="withdraw-main">

            <div className="withdraw-btn">
              <button>Withdraw</button>
            </div>

            <div className="withdraw-dec">
              <h6>
                When your account reaches the minimum amount
                or more, you may request your earnings by
                clicking the above button. The payment is
                then sent to your withdraw account during business
                days no longer than 2 days after requesting. Please
                do not contact us regarding payments before due dates.
              </h6>

              <h6>
                In order to receive your payments you need to fill your
                payment method and payment ID here if you haven't done so.
                You are also requested to fill all the required fields
                in the Account Details section with accurate data.
              </h6>
            </div>

            <div className="withdraw-tableM">
              <table className="withdraw-table">
                <thead>
                  <tr>
                    <th><span>ID</span></th>
                    <th><span>Date</span></th>
                    <th>Status</th>
                    <th><span>Publisher Earnings</span></th>
                    <th><span>Referral Earnings</span></th>
                    <th>Total Amount</th>
                    <th>Withdrawal Method</th>
                    <th>Withdrawal Account</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="withdraw-rule">
              <ul>
                <li>Pending: The payment is being checked by our team.</li>
                <li>Approved: The payment has been approved and is waiting to be sent.</li>
                <li>Complete: The payment has been successfully sent to your payment account.</li>
                <li>Cancelled: The payment has been cancelled.</li>
                <li>Returned: The payment has been returned to your account.</li>
              </ul>
            </div>

          </div>

        </div>


        <PannelFooter />
      </div>

    </div>
  )
}

export default Withdraws